var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _vm.renderScrollList
        ? _c("order-item", {
            attrs: {
              id: "supplier",
              isList: true,
              type: "supplier",
              payload: _vm.scrollListPayload,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }